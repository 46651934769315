import { useState, useEffect, useContext } from 'react';
import { getTranslation } from '../Utils';
import { SaveDataContext } from '../context/SaveDataContext';
import WeatherCard from './WeatherCard';
import GameResult from './GameResult';
import '../css/WeatherGame.css';

const languages = {
    'English': 'en',
    'Spanish': 'sp',
    'French': 'fr',
    'Russian': 'ru',
    'Chinese': 'zh_cn',
    'Japanese': 'ja',
    'Portuguese': 'pt',
    'German': 'de',
}

const getRandomCity = (cityList) => {
    return cityList[Math.floor(Math.random() * cityList.length)];
}

const WeatherGame = ({ cityList, selectedCity }) => {
    const { data, saveData } = useContext(SaveDataContext);
    const { language, unitType, unitTemperature, difficulty } = data;
    
    const [city, setCity] = useState([]);
    const [showTemp, setShowTemp] = useState(false);
    const [guessInput, setGuessInput] = useState(0);
    const [loading, setLoading] = useState(false);
    
    const getWeather = async (city) => {
        setLoading(true);

        const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?id=${city.id}&lang=${languages[language]}&appid=c223366e58c04a0dd28582718ea642ff&units=${unitType}`);
        const weatherData = await response.json();
        
        weatherData.state = city.state;
        setCity(weatherData);
        setShowTemp(false);
        setLoading(false);
    }
    
    const playGame = () => {
        const chosenCity = selectedCity || getRandomCity(cityList);

        getWeather(chosenCity);
        setShowTemp(false);
        setGuessInput('');
    }

    const handleInput = (event) => {
        const value = event.target.value;
        
        if (!isNaN(value)) {
            setGuessInput(value);
        }
    }

    const onSubmit = (event) => {
        event.preventDefault();

        if (guessInput !== '') {
            const { totalGuesses, marginTotal } = data;
            const temp = difficulty === 'hard' ? city.main.temp.toFixed(1) : Math.round(city.main.temp);
            const marginError = Number(guessInput) - temp;

            setShowTemp(true);
            saveData({ totalGuesses: totalGuesses + 1, marginTotal: marginTotal + marginError });
        }
    }

    useEffect(() => {
        playGame();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='weather-game'>
            {
                loading
                ? (
                    <div className='loading'></div>
                )
                : (
                    city.sys && <div className='game'>
                        <WeatherCard city={city} showTemp={showTemp} />
                        <div className='input-area'>
                            {!showTemp && <form onSubmit={onSubmit}>
                                <label className='temperature-guess'>
                                    <div className='guess-input'>
                                        <input type='number' value={guessInput} onChange={handleInput} step={difficulty === 'hard' ? 0.1 : 1} min={-200} max={200} autoFocus />
                                        <span>°{unitTemperature}</span>
                                    </div>
                                </label>
                                <button>{getTranslation(language, 'Guess the Temperature')}</button>
                            </form>}

                            {showTemp && <div className='result'>
                                <GameResult city={city} guessInput={Number(guessInput)} />
                                {!selectedCity && <button onClick={playGame}>{getTranslation(language, 'Play Again')}</button>}
                            </div>}
                        </div>
                    </div>
                )
            }
        </div>
    );
}

export default WeatherGame;
