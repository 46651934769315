import { useState, useContext } from 'react';
import { getTranslation } from '../Utils';
import { SaveDataContext } from '../context/SaveDataContext';
import '../css/Settings.css';

const Settings = () => {
    const { data, saveData } = useContext(SaveDataContext);
    const { language, unitType, timeFormat, difficulty } = data;

    const [unitTypeSetting, setUnitTypeSetting] = useState(unitType || 'imperial');
    const [timeFormatSetting, setTimeFormatSetting] = useState(timeFormat || '12hr');
    const [difficultySetting, setDifficultySetting] = useState(difficulty || 'normal');

    const onSettingChange = (event) => {
        const { name, value } = event.target;
        
        if (name === 'unitType') {
            setUnitTypeSetting(value);
            saveData({ unitType: value, unitTemperature: value === 'imperial' ? 'F' : 'C', unitSpeed: value === 'imperial' ? 'mph' : 'km/h' });
        }
        else if (name === 'timeFormat') {
            setTimeFormatSetting(value);
            saveData({ timeFormat: value });
        }
        else if (name === 'difficulty') {
            setDifficultySetting(value);
            saveData({ difficulty: value });
        }
    }
    
    return (
        <div className='settings'>
            <h2>{getTranslation(language, 'General')}</h2>
            <form>
                <label className='form-setting'>
                    {getTranslation(language, 'Language')}
                    <select value={language} onChange={(event) => saveData({ language: event.target.value })}>
                        <option value='English'>English</option>
                        <option value='Spanish'>Español</option>
                        <option value='French'>Français</option>
                        <option value='Russian'>Русский</option>
                        <option value='Chinese'>中文</option>
                        <option value='Japanese'>日本語</option>
                        <option value='Portuguese'>Português</option>
                        <option value='German'>Deutsch</option>
                    </select>
                </label>

                <hr />

                <label className='form-setting'>
                    {getTranslation(language, 'Units')}
                    <div className='setting-switch'>
                        <input type='radio' id='unitType1' name='unitType' value='imperial' onChange={(event) => onSettingChange(event)} checked={unitTypeSetting === 'imperial'} />
                        <label htmlFor='unitType1'>{getTranslation(language, 'Imperial')}</label>
                        <input type='radio' id='unitType2' name='unitType' value='metric' onChange={(event) => onSettingChange(event)} checked={unitTypeSetting === 'metric'} />
                        <label htmlFor='unitType2'>{getTranslation(language, 'Metric')}</label>
                    </div>
                </label>

                <hr />

                <label className='form-setting'>
                    {getTranslation(language, 'Time Format')}
                    <div className='setting-switch'>
                        <input type='radio' id='timeFormat1' name='timeFormat' value='12hr' onChange={(event) => onSettingChange(event)} checked={timeFormatSetting === '12hr'} />
                        <label htmlFor='timeFormat1'>12hr</label>
                        <input type='radio' id='timeFormat2' name='timeFormat' value='24hr' onChange={(event) => onSettingChange(event)} checked={timeFormatSetting === '24hr'} />
                        <label htmlFor='timeFormat2'>24hr</label>
                    </div>
                </label>
            </form>

            <h2>{getTranslation(language, 'Game')}</h2>
            <form>
                <label className="form-setting">
                    {getTranslation(language, 'Difficulty')}
                    <div className='setting-switch'>
                        <input type='radio' id='difficulty1' name='difficulty' value='normal' onChange={(event) => onSettingChange(event)} checked={difficultySetting === 'normal'} />
                        <label htmlFor='difficulty1'>{getTranslation(language, 'Normal')}</label>
                        <input type='radio' id='difficulty2' name='difficulty' value='hard' onChange={(event) => onSettingChange(event)} checked={difficultySetting === 'hard'} />
                        <label htmlFor='difficulty2'>{getTranslation(language, 'Hard')}</label>
                    </div>
                </label>
            </form>
        </div>
    );
}

export default Settings;
