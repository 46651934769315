const SaveDataReducer = (state, action) => {
    switch(action.type) {
        case 'ADD_FAVORITE':
            return {
                ...state,
                favorites: [action.payload, ...state.favorites]
            }
        case 'DELETE_FAVORITE':
            return {
                ...state,
                favorites: state.favorites.filter(favorite => favorite.id !== action.payload)
            }
        case 'ADD_DAILY':
            return {
                ...state,
                daily: [action.payload, ...state.daily]
            }
        case 'SAVE_DATA':
            return {
               ...state,
               ...action.payload
            }
        default:
            return state;
    }
}

export default SaveDataReducer;