import { useContext } from 'react';
import { countryCodeToName, stateCodeToName, images, getTranslation } from '../Utils';
import { SaveDataContext } from '../context/SaveDataContext';
import '../css/WeatherCard.css';

const getLocalTime = (city, timeFormat) => {
    const unixTimestamp = city.dt;
    const timezoneOffset = city.timezone;
    const timestampMillis = unixTimestamp * 1000;
    const date = new Date(timestampMillis);
    const localTime = new Date(date.getTime() + timezoneOffset * 1000);
    const setTimeFormat = timeFormat === '12hr' ? true : false;
    const formattedLocalTime = localTime.toLocaleTimeString([], { timeZone: 'GMT', hour12: setTimeFormat, timeStyle: 'short' });

    return formattedLocalTime;
}

const WeatherCard = ({ city, showTemp }) => {
    const { data } = useContext(SaveDataContext);
    const { language, difficulty, unitTemperature, unitSpeed, includeHighLow, timeFormat } = data;

    const country = countryCodeToName[city.sys.country];
    const state = stateCodeToName[city.state] || '';
    const temp = difficulty === 'hard' ? city.main.temp.toFixed(1) : Math.round(city.main.temp);
    const tempMax = difficulty === 'hard' ? city.main.temp_max.toFixed(1) : Math.round(city.main.temp_max);
    const tempMin = difficulty === 'hard' ? city.main.temp_min.toFixed(1) : Math.round(city.main.temp_min);
    const desc = city.weather[0].description;
    const description = desc.charAt(0).toUpperCase() + desc.slice(1);
    
    return (
        <div className='weather-card'>
            <h1>{`${city.name}, ${state.length > 0 ? state + ', ' : ''}${country}`}</h1>
            <span className='local-time'>{`${getTranslation(language, 'Local Time')}: ${getLocalTime(city, timeFormat)}`}</span>

            <div className='card-middle'>
                <div className='weather-card-img'>
                    <img src={images[`img${city.weather[0].icon}`]} alt='Weather icon' />
                </div>
                <div className='temp-info'>
                    <span className='description'>{description}</span>
                    <h2>{`${showTemp ? temp : '???'}`}°<span className='temp-unit'>{unitTemperature}</span></h2>
                </div>
            </div>

            <div className='extra-info-div'>
                <div className='extra-info'>
                    <span className='extra-info-text'>{getTranslation(language, 'Humidity')}</span>
                    <span>{city.main.humidity}%</span>
                </div>
                <div className='extra-info'>
                    <span className='extra-info-text'>{getTranslation(language, 'Wind')}</span>
                    <span>{city.wind.speed.toFixed(1)} {unitSpeed}</span>
                </div>
                {includeHighLow && <div className='high-low'>
                    <span>{`High: ${tempMax}°${unitTemperature}`}</span>
                    <span>{`Low: ${tempMin}°${unitTemperature}`}</span>
                </div>}
            </div>
        </div>
    );
}

export default WeatherCard;
