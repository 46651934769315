import { useEffect, useContext } from 'react';
import { getTranslation } from '../Utils';
import { SaveDataContext } from '../context/SaveDataContext';
import '../css/GameResult.css';

const GameResult = ({ city, guessInput }) => {
    const { data, saveData } = useContext(SaveDataContext);
    const { language, difficulty, unitTemperature, totalGuesses, tooHot, tooCold, soClose, perfectGuesses, perfectStreak, bestStreak, marginTotal } = data;

    const temp = difficulty === 'hard' ? city.main.temp.toFixed(1) : Math.round(city.main.temp);
    const userGuess = difficulty === 'hard' ? guessInput.toFixed(1) : Math.round(guessInput);

    useEffect(() => {
        if (userGuess === temp) {
            saveData({ perfectGuesses: perfectGuesses + 1, perfectStreak: perfectStreak + 1 });

            if (bestStreak < perfectStreak + 1) {
                saveData({ bestStreak: perfectStreak + 1 });
            }
        }
        else if (Math.abs(userGuess - temp) <= 1) {
            saveData({ soClose: soClose + 1, perfectStreak: 0 });
        }
        else if (userGuess > temp) {
            saveData({ tooHot: tooHot + 1, perfectStreak: 0 });
        }
        else {
            saveData({ tooCold: tooCold + 1, perfectStreak: 0 });
        }

        saveData({ avgMarginError: marginTotal / totalGuesses });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <div className='game-result'>
            <h1>{getTranslation(language, 'Your guess')}</h1>
            <h2>{`${userGuess}°${unitTemperature}`}</h2>
            {
                userGuess === temp
                ? (
                    <h3 className='correct'>{getTranslation(language, 'You got it!')}</h3>
                )
                : (
                    Math.abs(userGuess - temp) <= 1
                    ? (
                        <h3 className='so-close'>{getTranslation(language, 'So close')}</h3>
                    )
                    : (
                        userGuess > temp
                        ? (
                            <h3 className='hot'>{getTranslation(language, 'Too hot')}</h3>
                        )
                        : (
                            <h3 className='cold'>{getTranslation(language, 'Too cold')}</h3>
                        )
                    )
                )
            }
        </div>
    );
}

export default GameResult;