import { useState, useContext } from 'react';
import { getTranslation } from '../Utils';
import { SaveDataContext } from '../context/SaveDataContext';
import '../css/Stats.css';

const Stats = () => {
    const { data, saveData } = useContext(SaveDataContext);
    const { language, unitTemperature, totalGuesses, tooHot, tooCold, soClose, perfectGuesses, bestStreak, avgMarginError } = data;
    const [showConfirmation, setShowConfirmation] = useState(false);

    const resetStats = () => {
        saveData({
            totalGuesses: 0,
            tooHot: 0,
            tooCold: 0,
            soClose: 0,
            perfectGuesses: 0,
            perfectStreak: 0,
            marginTotal: 0,
            avgMarginError: 0
        });
        setShowConfirmation(false);
    }
    
    return (
        <div className='stats'>
            <div className='stats-table'>
                <div className='stats-row'>
                    <span className='table-text'>{getTranslation(language, 'Total Guesses')}</span>
                    <span id="total-guesses">{totalGuesses}</span>
                </div>

                <hr />

                <div className='stats-row'>
                    <span className='table-text'>{getTranslation(language, 'Too Hot')}</span>
                    <span id="too-hot">{tooHot}</span>
                </div>

                <hr />

                <div className='stats-row'>
                    <span className='table-text'>{getTranslation(language, 'Too Cold')}</span>
                    <span id="too-cold">{tooCold}</span>
                </div>

                <hr />

                <div className='stats-row'>
                    <span className='table-text'>{getTranslation(language, 'So Close')}</span>
                    <span id='so-close'>{soClose}</span>
                </div>

                <hr />
                
                <div className='stats-row'>
                    <span className='table-text'>{getTranslation(language, 'Perfect Guesses')}</span>
                    <span id='perfect-guesses'>{perfectGuesses}</span>
                </div>

                <hr />
                
                <div className='stats-row'>
                    <span className='table-text'>{getTranslation(language, 'Best Perfect Streak')}</span>
                    <span id='perfect-guesses'>{bestStreak}</span>
                </div>

                <hr />

                <div className='stats-row'>
                    <span className='table-text'>{getTranslation(language, 'Avg Margin of Error')}</span>
                    <span id='avg-margin-error'>{avgMarginError.toFixed(1)}°{unitTemperature}</span>
                </div>
            </div>

            {
                totalGuesses > 0 &&
                !showConfirmation &&
                <button className='reset-stats' onClick={() => setShowConfirmation(!showConfirmation)}>{getTranslation(language, 'Reset Stats')}</button>
            }

            {showConfirmation && <div className='delete-confirm'>
                <p>{getTranslation(language, 'Are you sure you want to reset your stats? This cannot be undone.')}</p>
                <div className='confirm-btns'>
                    <button className='confirm-delete' onClick={resetStats}>{getTranslation(language, 'Reset')}</button>
                    <button className='confirm-cancel' onClick={() => setShowConfirmation(false)}>{getTranslation(language, 'Cancel')}</button>
                </div>
            </div>}
        </div>
    );
}

export default Stats;
