import img01d from './assets/images/01d.svg';
import img01n from './assets/images/01n.svg';
import img02d from './assets/images/02d.svg';
import img02n from './assets/images/02n.svg';
import img03d from './assets/images/03d.svg';
import img03n from './assets/images/03n.svg';
import img04d from './assets/images/04d.svg';
import img04n from './assets/images/04n.svg';
import img09d from './assets/images/09d.svg';
import img09n from './assets/images/09n.svg';
import img10d from './assets/images/10d.svg';
import img10n from './assets/images/10n.svg';
import img11d from './assets/images/11d.svg';
import img11n from './assets/images/11n.svg';
import img13d from './assets/images/13d.svg';
import img13n from './assets/images/13n.svg';
import img50d from './assets/images/50d.svg';
import img50n from './assets/images/50n.svg';

export const images = {
    img01d,
    img01n,
    img02d,
    img02n,
    img03d,
    img03n,
    img04d,
    img04n,
    img09d,
    img09n,
    img10d,
    img10n,
    img11d,
    img11n,
    img13d,
    img13n,
    img50d,
    img50n
}

export const countryCodeToName = {
    'AF': 'Afghanistan',
    'AL': 'Albania',
    'DZ': 'Algeria',
    'AS': 'American Samoa',
    'AD': 'Andorra',
    'AO': 'Angola',
    'AI': 'Anguilla',
    'AQ': 'Antarctica',
    'AG': 'Antigua and Barbuda',
    'AR': 'Argentina',
    'AM': 'Armenia',
    'AW': 'Aruba',
    'AU': 'Australia',
    'AT': 'Austria',
    'AZ': 'Azerbaijan',
    'BS': 'Bahamas',
    'BH': 'Bahrain',
    'BD': 'Bangladesh',
    'BB': 'Barbados',
    'BY': 'Belarus',
    'BE': 'Belgium',
    'BZ': 'Belize',
    'BJ': 'Benin',
    'BM': 'Bermuda',
    'BT': 'Bhutan',
    'BO': 'Bolivia',
    'BQ': 'Bonaire',
    'BA': 'Bosnia and Herzegovina',
    'BW': 'Botswana',
    'BV': 'Bouvet Island',
    'BR': 'Brazil',
    'IO': 'British Indian Ocean Territory',
    'BN': 'Brunei Darussalam',
    'BG': 'Bulgaria',
    'BF': 'Burkina Faso',
    'BI': 'Burundi',
    'CV': 'Cabo Verde',
    'KH': 'Cambodia',
    'CM': 'Cameroon',
    'CA': 'Canada',
    'KY': 'Cayman Islands',
    'CF': 'Central African Republic',
    'TD': 'Chad',
    'CL': 'Chile',
    'CN': 'China',
    'CX': 'Christmas Island',
    'CC': 'Cocos (Keeling) Islands',
    'CO': 'Colombia',
    'KM': 'Comoros',
    'CD': 'Democratic Republic of the Congo',
    'CG': 'Congo',
    'CK': 'Cook Islands',
    'CR': 'Costa Rica',
    'HR': 'Croatia',
    'CU': 'Cuba',
    'CW': 'Curaçao',
    'CY': 'Cyprus',
    'CZ': 'Czechia',
    'CI': "Côte d'Ivoire",
    'DK': 'Denmark',
    'DJ': 'Djibouti',
    'DM': 'Dominica',
    'DO': 'Dominican Republic',
    'EC': 'Ecuador',
    'EG': 'Egypt',
    'SV': 'El Salvador',
    'GQ': 'Equatorial Guinea',
    'ER': 'Eritrea',
    'EE': 'Estonia',
    'SZ': 'Eswatini',
    'ET': 'Ethiopia',
    'FK': 'Falkland Islands',
    'FO': 'Faroe Islands',
    'FJ': 'Fiji',
    'FI': 'Finland',
    'FR': 'France',
    'GF': 'French Guiana',
    'PF': 'French Polynesia',
    'TF': 'French Southern Territories',
    'GA': 'Gabon',
    'GM': 'Gambia',
    'GE': 'Georgia',
    'DE': 'Germany',
    'GH': 'Ghana',
    'GI': 'Gibraltar',
    'GR': 'Greece',
    'GL': 'Greenland',
    'GD': 'Grenada',
    'GP': 'Guadeloupe',
    'GU': 'Guam',
    'GT': 'Guatemala',
    'GG': 'Guernsey',
    'GN': 'Guinea',
    'GW': 'Guinea-Bissau',
    'GY': 'Guyana',
    'HT': 'Haiti',
    'HM': 'Heard Island and McDonald Islands',
    'VA': 'Holy See',
    'HN': 'Honduras',
    'HK': 'Hong Kong',
    'HU': 'Hungary',
    'IS': 'Iceland',
    'IN': 'India',
    'ID': 'Indonesia',
    'IR': 'Iran',
    'IQ': 'Iraq',
    'IE': 'Ireland',
    'IM': 'Isle of Man',
    'IL': 'Israel',
    'IT': 'Italy',
    'JM': 'Jamaica',
    'JP': 'Japan',
    'JE': 'Jersey',
    'JO': 'Jordan',
    'KZ': 'Kazakhstan',
    'KE': 'Kenya',
    'KI': 'Kiribati',
    'KP': 'North Korea',
    'KR': 'South Korea',
    'KW': 'Kuwait',
    'KG': 'Kyrgyzstan',
    'LA': "Lao People's Democratic Republic",
    'LV': 'Latvia',
    'LB': 'Lebanon',
    'LS': 'Lesotho',
    'LR': 'Liberia',
    'LY': 'Libya',
    'LI': 'Liechtenstein',
    'LT': 'Lithuania',
    'LU': 'Luxembourg',
    'MO': 'Macao',
    'MG': 'Madagascar',
    'MW': 'Malawi',
    'MY': 'Malaysia',
    'MV': 'Maldives',
    'ML': 'Mali',
    'MT': 'Malta',
    'MH': 'Marshall Islands',
    'MQ': 'Martinique',
    'MR': 'Mauritania',
    'MU': 'Mauritius',
    'YT': 'Mayotte',
    'MX': 'Mexico',
    'FM': 'Micronesia',
    'MD': 'Moldova',
    'MC': 'Monaco',
    'MN': 'Mongolia',
    'ME': 'Montenegro',
    'MS': 'Montserrat',
    'MA': 'Morocco',
    'MZ': 'Mozambique',
    'MM': 'Myanmar',
    'NA': 'Namibia',
    'NR': 'Nauru',
    'NP': 'Nepal',
    'NL': 'Netherlands',
    'NC': 'New Caledonia',
    'NZ': 'New Zealand',
    'NI': 'Nicaragua',
    'NE': 'Niger',
    'NG': 'Nigeria',
    'NU': 'Niue',
    'NF': 'Norfolk Island',
    'MP': 'Northern Mariana Islands',
    'NO': 'Norway',
    'OM': 'Oman',
    'PK': 'Pakistan',
    'PW': 'Palau',
    'PS': 'Palestine',
    'PA': 'Panama',
    'PG': 'Papua New Guinea',
    'PY': 'Paraguay',
    'PE': 'Peru',
    'PH': 'Philippines',
    'PN': 'Pitcairn',
    'PL': 'Poland',
    'PT': 'Portugal',
    'PR': 'Puerto Rico',
    'QA': 'Qatar',
    'MK': 'Republic of North Macedonia',
    'RO': 'Romania',
    'RU': 'Russia',
    'RW': 'Rwanda',
    'RE': 'Réunion',
    'BL': 'Saint Barthélemy',
    'SH': 'Saint Helena',
    'KN': 'Saint Kitts and Nevis',
    'LC': 'Saint Lucia',
    'MF': 'Saint Martin',
    'PM': 'Saint Pierre and Miquelon',
    'VC': 'Saint Vincent and the Grenadines',
    'WS	': 'Samoa',
    'SM': 'San Marino',
    'ST': 'Sao Tome and Principe',
    'SA': 'Saudi Arabia',
    'SN': 'Senegal',
    'RS': 'Serbia',
    'SC': 'Seychelles',
    'SL': 'Sierra Leone',
    'SG': 'Singapore',
    'SX': 'Sint Maarten',
    'SK': 'Slovakia',
    'SI': 'Slovenia',
    'SB': 'Solomon Islands',
    'SO': 'Somalia',
    'ZA': 'South Africa',
    'GS': 'South Georgia and the South Sandwich Islands',
    'SS': 'South Sudan',
    'ES': 'Spain',
    'LK': 'Sri Lanka',
    'SD': 'Sudan',
    'SR': 'Suriname',
    'SJ': 'Svalbard and Jan Mayen',
    'SE': 'Sweden',
    'CH': 'Switzerland',
    'SY': 'Syria',
    'TW': 'Taiwan',
    'TJ': 'Tajikistan',
    'TZ': 'Tanzania',
    'TH': 'Thailand',
    'TL': 'Timor-Leste',
    'TG': 'Togo',
    'TK': 'Tokelau',
    'TO': 'Tonga',
    'TT': 'Trinidad and Tobago',
    'TN': 'Tunisia',
    'TR': 'Turkey',
    'TM': 'Turkmenistan',
    'TC': 'Turks and Caicos Islands',
    'TV': 'Tuvalu',
    'UG': 'Uganda',
    'UA': 'Ukraine',
    'AE': 'United Arab Emirates',
    'GB': 'United Kingdom',
    'UM': 'United States Minor Outlying Islands',
    'US': 'United States of America',
    'UY': 'Uruguay',
    'UZ': 'Uzbekistan',
    'VU': 'Vanuatu',
    'VE': 'Venezuela',
    'VN': 'Vietnam',
    'VG': 'British Virgin Islands',
    'VI': 'U.S. Virgin Islands',
    'WF': 'Wallis and Futuna',
    'EH': 'Western Sahara',
    'YE': 'Yemen',
    'ZM': 'Zambia',
    'ZW': 'Zimbabwe',
    'AX': 'Åland Islands',
    'XK': 'Kosovo',
    'WS': 'Samoa'
}

export const stateCodeToName = {
    'AL': 'Alabama',
    'AK': 'Alaska',
    'AZ': 'Arizona',
    'AR': 'Arkansas',
    'CA': 'California',
    'CO': 'Colorado',
    'CT': 'Connecticut',
    'DE': 'Delaware',
    'FL': 'Florida',
    'GA': 'Georgia',
    'HI': 'Hawaii',
    'ID': 'Idaho',
    'IL': 'Illinois',
    'IN': 'Indiana',
    'IA': 'Iowa',
    'KS': 'Kansas',
    'KY': 'Kentucky',
    'LA': 'Louisiana',
    'ME': 'Maine',
    'MD': 'Maryland',
    'MA': 'Massachusetts',
    'MI': 'Michigan',
    'MN': 'Minnesota',
    'MS': 'Mississippi',
    'MO': 'Missouri',
    'MT': 'Montana',
    'NE': 'Nebraska',
    'NV': 'Nevada',
    'NH': 'New Hampshire',
    'NJ': 'New Jersey',
    'NM': 'New Mexico',
    'NY': 'New York',
    'NC': 'North Carolina',
    'ND': 'North Dakota',
    'OH': 'Ohio',
    'OK': 'Oklahoma',
    'OR': 'Oregon',
    'PA': 'Pennsylvania',
    'RI': 'Rhode Island',
    'SC': 'South Carolina',
    'SD': 'South Dakota',
    'TN': 'Tennessee',
    'TX': 'Texas',
    'UT': 'Utah',
    'VT': 'Vermont',
    'VA': 'Virginia',
    'WA': 'Washington',
    'WV': 'West Virginia',
    'WI': 'Wisconsin',
    'WY': 'Wyoming',
    'DC': 'District of Columbia',
    '00': 'American Samoa'
}

export const translations = {
    'Spanish': {
        // Menu
        'Email the dev': 'Envíe un correo electrónico al desarrollador',
        'Play': 'Jugar',
        'Favorites': 'Favoritos',
        'Stats': 'Estadística',
        'Settings': 'Ajustes',
        // Game
        'WeatherGuesser': 'WeatherGuesser',
        'Local Time': 'Hora Local',
        'Humidity': 'Humedad',
        'Wind': 'Viento',
        'Guess the Temperature': 'Adivina la Temperatura',
        'Your Guess': 'Tu Suposición',
        'Too hot': 'Demasiado caliente',
        'Too cold': 'Demasiado frio',
        'So close': 'Tan cerca',
        'You got it!': '¡Lo entendiste!',
        'Play Again': 'Jugar de Nuevo',
        // Favorites
        'Search cities': 'Buscar ciudades',
        'Edit': 'Editar',
        'Cancel': 'Cancelar',
        'Add your favorite cities here': 'Añade aquí tus ciudades favoritas',
        'Delete from favorites': 'Eliminar de favoritos',
        // Stats
        'Total Guesses': 'Conjeturas Totales',
        'Too Hot': 'Demasiado Caliente',
        'Too Cold': 'Demasiado Frio',
        'So Close': 'Tan Cerca',
        'Perfect Guesses': 'Conjeturas Perfectas',
        'Best Perfect Streak': 'Mejor Racha Perfecta',
        'Avg Margin of Error': 'Margen de Error Promedio',
        'Reset Stats': 'Restablecer Estadísticas',
        'Are you sure you want to reset your stats? This cannot be undone.': '¿Estás seguro de que quieres restablecer tus estadísticas? Esto no se puede deshacer.',
        'Reset': 'Reiniciar',
        // Settings
        'General': 'General',
        'Language': 'Idioma',
        'Units': 'Unidades',
        'Imperial': 'Imperial',
        'Metric': 'Métrico',
        'Time Format': 'Formato de hora',
        'Game': 'Juego',
        'Difficulty': 'Dificultad',
        'Normal': 'Normal',
        'Hard': 'Difícil',
        // Footer
        'Support a solo dev': 'Apoyar a un desarrollador en solitario',
        'Weather data provided by': 'Datos meteorológicos proporcionados por'
    },
    'French': {
        // Menu
        'Email the dev': 'Envoyer un e-mail au développeur',
        'Play': 'Jouer',
        'Favorites': 'Favoris',
        'Stats': 'Statistiques',
        'Settings': 'Paramètres',
        // Game
        'WeatherGuesser': 'WeatherGuesser',
        'Local Time': 'Heure locale',
        'Humidity': 'Humidité',
        'Wind': 'Vent',
        'Guess the Temperature': 'Devinez la Température',
        'Your Guess': 'Votre Supposition',
        'Too hot': 'Trop chaud',
        'Too cold': 'Trop froid',
        'So close': 'Si proche',
        'You got it!': "Vous l'avez compris!",
        'Play Again': 'Rejouer',
        // Favorites
        'Search cities': 'Rechercher des villes',
        'Edit': 'Modifier',
        'Cancel': 'Annuler',
        'Add your favorite cities here': 'Ajoutez ici vos villes préférées',
        'Delete from favorites': 'Supprimer des favoris',
        // Stats
        'Total Guesses': 'Total des Suppositions',
        'Too Hot': 'Trop Chaud',
        'Too Cold': 'Trop Froid',
        'So Close': 'Si Proche',
        'Perfect Guesses': 'Des Suppositions Parfaites',
        'Best Perfect Streak': 'Meilleure Séquence Parfaite',
        'Avg Margin of Error': "Marge D'erreur Moyenne",
        'Reset Stats': 'Réinitialiser les Statistiques',
        'Are you sure you want to reset your stats? This cannot be undone.': 'Êtes-vous sûr de vouloir réinitialiser vos statistiques? Cela ne peut pas être annulé.',
        'Reset': 'Réinitialiser',
        // Settings
        'General': 'Général',
        'Language': 'Langue',
        'Units': 'Unités',
        'Imperial': 'Impérial',
        'Metric': 'Métrique',
        'Time Format': "Format de L'heure",
        'Game': 'Jeu',
        'Difficulty': 'Difficulté',
        'Normal': 'Normale',
        'Hard': 'Dur',
        // Footer
        'Support a solo dev': 'Soutenir un développeur solo',
        'Weather data provided by': 'Données météorologiques fournies par'
    },
    'Russian': {
        // Menu
        'Email the dev': 'Напишите разработчику',
        'Play': 'Играть',
        'Favorites': 'Избранное',
        'Stats': 'Статистика',
        'Settings': 'Настройки',
        // Game
        'WeatherGuesser': 'WeatherGuesser',
        'Local Time': 'Местное время',
        'Humidity': 'Влажность',
        'Wind': 'Ветер',
        'Guess the Temperature': 'Угадай температуру',
        'Your Guess': 'Ваше предположение',
        'Too hot': 'Слишком жарко',
        'Too cold': 'Слишком холодно',
        'So close': 'Так близко',
        'You got it!': 'Вы поняли!',
        'Play Again': 'Играть снова',
        // Favorites
        'Search cities': 'Поиск городов',
        'Edit': 'Редактировать',
        'Cancel': 'Отмена',
        'Add your favorite cities here': 'Добавляйте сюда свои любимые города',
        'Delete from favorites': 'Удалить из избранного',
        // Stats
        'Total Guesses': 'Всего догадок',
        'Too Hot': 'Слишком жарко',
        'Too Cold': 'Слишком холодно',
        'So Close': 'Так близко',
        'Perfect Guesses': 'Идеальные догадки',
        'Best Perfect Streak': 'Лучшая идеальная серия',
        'Avg Margin of Error': 'Средняя погрешность',
        'Reset Stats': 'Сбросить статистику',
        'Are you sure you want to reset your stats? This cannot be undone.': 'Вы уверены, что хотите сбросить свою статистику? Это невозможно отменить.',
        'Reset': 'Перезагрузить',
        // Settings
        'General': 'Общий',
        'Language': 'Язык',
        'Units': 'Единицы',
        'Imperial': 'Империал',
        'Metric': 'Метрика',
        'Time Format': 'Формат времени',
        'Game': 'Игра',
        'Difficulty': 'Сложность',
        'Normal': 'Нормальный',
        'Hard': 'Жесткий',
        // Footer
        'Support a solo dev': 'Поддержите индивидуального разработчика',
        'Weather data provided by': 'Данные о погоде предоставлены'
    },
    'Chinese': {
        // Menu
        'Email the dev': '给开发者发邮件',
        'Play': '玩',
        'Favorites': '收藏夹',
        'Stats': '统计数据',
        'Settings': '设置',
        // Game
        'WeatherGuesser': 'WeatherGuesser',
        'Local Time': '当地时间',
        'Humidity': '湿度',
        'Wind': '风',
        'Guess the Temperature': '猜猜温度',
        'Your Guess': '你的猜测',
        'Too hot': '太热了',
        'Too cold': '太冷了',
        'So close': '如此接近',
        'You got it!': '你明白了！',
        'Play Again': '再玩一次',
        // Favorites
        'Search cities': '搜索城市',
        'Edit': '编辑',
        'Cancel': '取消',
        'Add your favorite cities here': '在这里添加您最喜欢的城市',
        'Delete from favorites': '从收藏夹中删除',
        // Stats
        'Total Guesses': '总猜测数',
        'Too Hot': '太热了',
        'Too Cold': '太冷了',
        'So Close': '如此接近',
        'Perfect Guesses': '完美的猜测',
        'Best Perfect Streak': '最佳完美连胜',
        'Avg Margin of Error': '平均误差幅度',
        'Reset Stats': '重置统计数据',
        'Are you sure you want to reset your stats? This cannot be undone.': '您确定要重置您的统计数据吗？此操作无法撤消。',
        'Reset': '重置',
        // Settings
        'General': '一般的',
        'Language': '语言',
        'Units': '单位',
        'Imperial': '帝国',
        'Metric': '公制',
        'Time Format': '时间格式',
        'Game': '游戏',
        'Difficulty': '困难',
        'Normal': '普通的',
        'Hard': '难的',
        // Footer
        'Support a solo dev': '支持独立开发者',
        'Weather data provided by': '天气数据提供者'
    },
    'Japanese': {
        // Menu
        'Email the dev': '開発者にメールで送信する',
        'Play': '遊ぶ',
        'Favorites': 'お気に入り',
        'Stats': '統計',
        'Settings': '設定',
        // Game
        'WeatherGuesser': 'WeatherGuesser',
        'Local Time': '現地時間',
        'Humidity': '湿度',
        'Wind': '風',
        'Guess the Temperature': '温度を推測してください',
        'Your Guess': 'あなたの推測',
        'Too hot': '暑すぎる',
        'Too cold': '寒すぎる',
        'So close': 'とても近い',
        'You got it!': 'わかりました！',
        'Play Again': 'もう一度プレイする',
        // Favorites
        'Search cities': '都市を検索',
        'Edit': '編集',
        'Cancel': 'キャンセル',
        'Add your favorite cities here': 'お気に入りの都市をここに追加してください',
        'Delete from favorites': 'お気に入りから削除',
        // Stats
        'Total Guesses': '合計推測数',
        'Too Hot': '暑すぎる',
        'Too Cold': '寒すぎる',
        'So Close': 'とても近い',
        'Perfect Guesses': '完璧な推測',
        'Best Perfect Streak': 'ベストパーフェクトストリーク',
        'Avg Margin of Error': '平均誤差範囲',
        'Reset Stats': '統計のリセット',
        'Are you sure you want to reset your stats? This cannot be undone.': '統計をリセットしてもよろしいですか?これを元に戻すことはできません。',
        'Reset': 'リセット',
        // Settings
        'General': '一般的な',
        'Language': '言語',
        'Units': '単位',
        'Imperial': 'インペリアル',
        'Metric': 'メトリック',
        'Time Format': '時間の形式',
        'Game': 'ゲーム',
        'Difficulty': '困難',
        'Normal': '普通',
        'Hard': '難しい',
        // Footer
        'Support a solo dev': '個人開発者をサポートする',
        'Weather data provided by': '気象データ提供：'
    },
    'Portuguese': {
        // Menu
        'Email the dev': 'Envie um e-mail para o desenvolvedor',
        'Play': 'Jogar',
        'Favorites': 'Favoritos',
        'Stats': 'Estatísticos',
        'Settings': 'Configurações',
        // Game
        'WeatherGuesser': 'WeatherGuesser',
        'Local Time': 'Hora Local',
        'Humidity': 'Umidade',
        'Wind': 'Vento',
        'Guess the Temperature': 'Adivinhe a Temperatura',
        'Your Guess': 'Seu Palpite',
        'Too hot': 'Muito quente',
        'Too cold': 'Muito frio',
        'So close': 'Tão perto',
        'You got it!': 'Você conseguiu!',
        'Play Again': 'Jogue Novamente',
        // Favorites
        'Search cities': 'Pesquisar cidades',
        'Edit': 'Editar',
        'Cancel': 'Cancelar',
        'Add your favorite cities here': 'Adicione suas cidades favoritas aqui',
        'Delete from favorites': 'Excluir dos favoritos',
        // Stats
        'Total Guesses': 'Suposições Totais',
        'Too Hot': 'Muito Quente',
        'Too Cold': 'Muito Frio',
        'So Close': 'Tão Perto',
        'Perfect Guesses': 'Suposições Perfeitas',
        'Best Perfect Streak': 'Melhor Sequência Perfeita',
        'Avg Margin of Error': 'Margem Média de Erro',
        'Reset Stats': 'Redefinir Estatísticas',
        'Are you sure you want to reset your stats? This cannot be undone.': 'Tem certeza de que deseja redefinir suas estatísticas? Isto não pode ser desfeito.',
        'Reset': 'Reiniciar',
        // Settings
        'General': 'Em Geral',
        'Language': 'Linguagem',
        'Units': 'Unidades',
        'Imperial': 'Imperial',
        'Metric': 'Métrico',
        'Time Format': 'Formato de Hora',
        'Game': 'Jogo',
        'Difficulty': 'Dificuldade',
        'Normal': 'Normal',
        'Hard': 'Duro',
        // Footer
        'Support a solo dev': 'Apoie um desenvolvedor solo',
        'Weather data provided by': 'Dados meteorológicos fornecidos por'
    },
    'German': {
        // Menu
        'Email the dev': 'Senden Sie eine E-Mail an den Entwickler',
        'Play': 'Spielen',
        'Favorites': 'Favoriten',
        'Stats': 'Statistiken',
        'Settings': 'Einstellungen',
        // Game
        'WeatherGuesser': 'WeatherGuesser',
        'Local Time': 'Ortszeit',
        'Humidity': 'Luftfeuchtigkeit',
        'Wind': 'Wind',
        'Guess the Temperature': 'Erraten Sie die Temperatur',
        'Your Guess': 'Ihre Vermutung',
        'Too hot': 'Zu heiß',
        'Too cold': 'Zu kalt',
        'So close': 'So nah',
        'You got it!': 'Du hast es verstanden!',
        'Play Again': 'Spielen Sie noch einmal',
        // Favorites
        'Search cities': 'Städte suchen',
        'Edit': 'Bearbeiten',
        'Cancel': 'Stornieren',
        'Add your favorite cities here': 'Fügen Sie hier Ihre Lieblingsstädte hinzu',
        'Delete from favorites': 'Aus Favoriten löschen',
        // Stats
        'Total Guesses': 'Gesamtschätzungen',
        'Too Hot': 'Zu heiß',
        'Too Cold': 'Zu kalt',
        'So Close': 'So nah',
        'Perfect Guesses': 'Perfekte Vermutungen',
        'Best Perfect Streak': 'Bester perfekter Streak',
        'Avg Margin of Error': 'Durchschnittliche Fehlerquote',
        'Reset Stats': 'Statistiken zurücksetzen',
        'Are you sure you want to reset your stats? This cannot be undone.': 'Sind Sie sicher, dass Sie Ihre Statistiken zurücksetzen möchten? Dies kann nicht rückgängig gemacht werden.',
        'Reset': 'Zurücksetzen',
        // Settings
        'General': 'Allgemein',
        'Language': 'Sprache',
        'Units': 'Einheiten',
        'Imperial': 'Kaiserlich',
        'Metric': 'Metrisch',
        'Time Format': 'Zeitformat',
        'Game': 'Spiel',
        'Difficulty': 'Schwierigkeit',
        'Normal': 'Normal',
        'Hard': 'Hart',
        // Footer
        'Support a solo dev': 'Unterstützen Sie einen Einzelentwickler',
        'Weather data provided by': 'Wetterdaten bereitgestellt von'
    },

    /*
        // Menu
        'Email the dev': '',
        'Play': '',
        'Favorites': '',
        'Stats': '',
        'Settings': '',
        // Game
        'WeatherGuesser': 'WeatherGuesser',
        'Local Time': '',
        'Humidity': '',
        'Wind': '',
        'Guess the Temperature': '',
        'Your Guess': '',
        'Too hot': '',
        'Too cold': '',
        'So close': '',
        'You got it!': '',
        'Play Again': '',
        // Favorites
        'Search cities': '',
        'Edit': '',
        'Cancel': '',
        'Add your favorite cities here': '',
        'Delete from favorites': '',
        // Stats
        'Total Guesses': '',
        'Too Hot': '',
        'Too Cold': '',
        'So Close': '',
        'Perfect Guesses': '',
        'Best Perfect Streak': '',
        'Avg Margin of Error': '',
        'Reset Stats': '',
        'Are you sure you want to reset your stats? This cannot be undone.': '',
        'Reset': '',
        // Settings
        'General': '',
        'Language': '',
        'Units': '',
        'Imperial': '',
        'Metric': '',
        'Time Format': '',
        'Game': '',
        'Difficulty': '',
        'Normal': '',
        'Hard': '',
        // Footer
        'Support a solo dev': '',
        'Weather data provided by': ''
    */
}

export const getTranslation = (language, text) => {
    if (language === 'English') {
        return text;
    }

    return translations[language][text];
}