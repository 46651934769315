import { useContext, useState } from 'react';
import { getTranslation } from './Utils';
import { SaveDataContext } from './context/SaveDataContext';
// import Favorites from './components/Favorites';
import WeatherGame from './components/WeatherGame';
import Stats from './components/Stats';
import Settings from './components/Settings';
import logo from './assets/images/logo.svg';
import './css/App.css';

const cityListJSON = require('./city-list.json');
const cityList = cityListJSON.filter(city => city.country !== '');

const tabs = {
    MAIN_MENU: 0,
    GAME: 1,
    FAVORITES: 2,
    STATS: 3,
    SETTINGS: 4
}

const tabHeadings = [
    '',
    'WeatherGuesser',
    'Favorites',
    'Stats',
    'Settings'
]

const App = () => {
    const { data } = useContext(SaveDataContext);
    const { language } = data;
    const [selectedTab, setSelectedTab] = useState(tabs.MAIN_MENU);

    const closeTab = () => {
        setSelectedTab(tabs.MAIN_MENU);
    }

    return (
        <div className='app'>
            <main className='app-container'>
                <div className='top-nav'>
                    {selectedTab !== tabs.MAIN_MENU && 
                        <svg className='arrow-back' onClick={closeTab} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <g data-name="Layer 2">
                                <g data-name="arrow-ios-back">
                                    <rect width="24" height="24" transform="rotate(90 12 12)" opacity="0"/>
                                    <path d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64z"/>
                                </g>
                            </g>
                        </svg>
                    }
                    
                    {selectedTab !== tabs.MAIN_MENU && <h1>{getTranslation(language, tabHeadings[selectedTab])}</h1>}

                    <a href="mailto:hybridx13.software@gmail.com" target="_blank" rel="noopener noreferrer" className='email' title={getTranslation(language, 'Email the dev')} aria-label="Email">
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                            <polyline points="22,6 12,13 2,6"></polyline>
                        </svg>
                    </a>
                </div>
                <div className='main-menu-container'>
                    {selectedTab === tabs.GAME && <WeatherGame cityList={cityList} />}
                    {/*selectedTab === tabs.FAVORITES && <Favorites cityList={cityList} />*/}
                    {selectedTab === tabs.STATS && <Stats />}
                    {selectedTab === tabs.SETTINGS && <Settings />}
                    
                    {
                        selectedTab === tabs.MAIN_MENU && <div className='main-menu'>
                            <h1>WeatherGuesser</h1>
                            <img src={logo} alt='WeatherGuesser logo' />
                        
                            <nav id='nav-tabs'>
                                <ul>
                                    <li className={selectedTab === tabs.GAME ? 'selected' : ''} onClick={() => setSelectedTab(tabs.GAME)}>
                                        <span>
                                            {getTranslation(language, 'Play')}
                                            <svg className='arrow-menu first-arrow' width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <g data-name="Layer 2">
                                                    <g data-name="arrow-ios-back">
                                                        <rect width="24" height="24" transform="rotate(90 12 12)" opacity="0"/>
                                                        <path d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64z"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </span>
                                    </li>
                                    {
                                        /*
                                        <li className={selectedTab === tabs.FAVORITES ? 'selected' : ''} onClick={() => setSelectedTab(tabs.FAVORITES)}>
                                            <span>
                                                {getTranslation(language, 'Favorites')}
                                                <svg className='arrow-menu' width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <g data-name="Layer 2">
                                                        <g data-name="arrow-ios-back">
                                                            <rect width="24" height="24" transform="rotate(90 12 12)" opacity="0"/>
                                                            <path d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64z"/>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>
                                        </li>
                                        */
                                    }
                                    <li className={selectedTab === tabs.STATS ? 'selected' : ''} onClick={() => setSelectedTab(tabs.STATS)}>
                                        <span>
                                        {getTranslation(language, 'Stats')}
                                            <svg className='arrow-menu' width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <g data-name="Layer 2">
                                                    <g data-name="arrow-ios-back">
                                                        <rect width="24" height="24" transform="rotate(90 12 12)" opacity="0"/>
                                                        <path d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64z"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </span>
                                    </li>
                                    <li className={selectedTab === tabs.SETTINGS ? 'selected' : ''} onClick={() => setSelectedTab(tabs.SETTINGS)}>
                                        <span>
                                        {getTranslation(language, 'Settings')}
                                            <svg className='arrow-menu' width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <g data-name="Layer 2">
                                                    <g data-name="arrow-ios-back">
                                                        <rect width="24" height="24" transform="rotate(90 12 12)" opacity="0"/>
                                                        <path d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64z"/>
                                                    </g>
                                                </g>
                                            </svg>
                                        </span>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    }
                </div>
            </main>
            <footer>
                <a href='https://www.buymeacoffee.com/hybridx13' className='donation-btn' target='_blank' rel='noopener noreferrer' title={`${getTranslation(language, 'Support a solo dev')} :)`}>
                    <img src='https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png' alt='Buy Me A Coffee' />
                </a>
                <span className='api-link'>{getTranslation(language, 'Weather data provided by')} <a href='https://openweathermap.org/' target='_blank' rel='noopener noreferrer'>OpenWeatherMap API</a></span>
            </footer>
        </div>
    );
}

export default App;
