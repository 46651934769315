import { createContext, useReducer, useEffect } from 'react';
import SaveDataReducer from './SaveDataReducer';

const initialData = {
    // Favorites
    favorites: [],
    // Daily
    daily: [],
    // Stats
    totalGuesses: 0,
    tooHot: 0,
    tooCold: 0,
    soClose: 0,
    perfectGuesses: 0,
    perfectStreak: 0,
    bestStreak: 0,
    marginTotal: 0,
    avgMarginError: 0,
    // Settings
    language: 'English',
    theme: 'clear-sky',
    unitType: 'imperial',
    unitTemperature: 'F',
    unitSpeed: 'mph',
    timeFormat: '12hr',
    difficulty: 'normal',
    includeHighLow: false,
    // Misc
    hints: 3
}

export const SaveDataContext = createContext(initialData);

export const SaveDataProvider = ({ children }) => {
    const savedData = JSON.parse(localStorage.getItem('data'));
    
    // Ensures any new data that's not already present gets added
    if (savedData) {
        for (const [key, val] of Object.entries(initialData)) {
            if (savedData[key] === undefined) {
                savedData[key] = val;
            }
        }
    }

    const [data, dispatch] = useReducer(SaveDataReducer, savedData || initialData);

    useEffect(() => {
        localStorage.setItem('data', JSON.stringify(data));
    }, [data]);

    const saveData = (newData) => {
        dispatch({
            type: 'SAVE_DATA',
            payload: newData
        });
    }

    const addFavorite = (favorite) => {
        dispatch({
            type: 'ADD_FAVORITE',
            payload: favorite
        });
    }

    const deleteFavorite = (id) => {
        dispatch({
            type: 'DELETE_FAVORITE',
            payload: id
        });
    }

    const addDaily = (daily) => {
        dispatch({
            type: 'ADD_DAILY',
            payload: daily
        });
    }

    

    return (
        <SaveDataContext.Provider value={{
            data,
            saveData,
            addFavorite,
            deleteFavorite,
            addDaily
        }}>
            {children}
        </SaveDataContext.Provider>
    );
}